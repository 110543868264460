import React, { FC, useState } from "react";
import rightImgPng from "images/elhilal.jpg";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";


export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  const [witchimage,setwitchimage]=useState(true);

function switchImage() {
//  setTimeout(() => { setwitchimage(!witchimage);  }, 2500); 
}

switchImage();

  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === "type1" ? "lg:pl-16" : "lg:pr-16"
        }`}
      >
        <span className="uppercase text-sm text-gray-400 tracking-widest">
        Aller-retour
        </span>
        <h2 className="font-semibold text-4xl mt-5">
        ALGÉRIE-FRANCE<br/><br/>
        <small><small>À partir de </small></small>
<strong className="text-green-500 text-6xl">199 <small>€</small></strong><br/>
<strong className="text-green-500 text-6xl ">22 900 <small>DZD</small></strong>
</h2>
<small>Offre valable dans la limite des sièges disponibles</small>

        <ul className="space-y-10 mt-16">
          <li className="space-y-4">
          <span className="block font-semibold">Période d'achat du 01 Février au 05 Avril 2025
            </span>
            <span className="block font-semibold">Période de voyage du 22 Février au 10 Avril 2025
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              <strong>10 Kg</strong> de bagage cabine.<br/>
              <strong>30 Kg</strong> de bagage en soute (jusqu'à 02 pièces).
            </span>
          </li>
        </ul>
      </div>


      <div className="flex-grow">
      <NcImage  src={rightImg} />

      </div>



    </div>
  );
};

export default SectionOurFeatures;
